.reach-us-container {
    padding: 20px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
}

.animated-header {
    animation: slideInFromTop 1s ease-out;
}

.contact-details {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
}

.animated-contact-details {
    animation: fadeInUp 1s ease-out;
}

.contact-info,
.address-info {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 45%;
    text-align: left;
}

.contact-info h2,
.address-info h2 {
    color: #007bff;
    font-size: 1.8em;
}

.social-media-section {
    margin: 30px 0;
}

.animated-social-media {
    animation: bounceIn 1s ease-in-out;
}

.social-media-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-media-icons a {
    font-size: 2em;
    color: #007bff;
    transition: transform 0.3s ease-in-out;
}

.social-media-icons a:hover {
    transform: scale(1.2);
    color: #0056b3;
}

.map-section {
    margin: 30px 0;
}

.animated-map {
    animation: zoomIn 1s ease-out;
}

.map-section iframe {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }

    70% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* Responsive */
@media (max-width: 768px) {
    .contact-details {
        flex-direction: column;
        align-items: center;
    }

    .contact-info,
    .address-info {
        width: 100%;
        margin-bottom: 20px;
    }

    .social-media-icons {
        flex-direction: column;
    }

    .social-media-icons a {
        margin-bottom: 10px;
    }

    .map-section iframe {
        width: 100%;
        height: 300px;
    }
}

.reach-us-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 20%;
    /* Adjust the width as needed */
    /* You can set a max-width to control the image size */
}