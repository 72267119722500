/* AboutUs.css */
.about-us-container {
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eaeef0;
  opacity: 0.9;
  /* Adjust the opacity if needed */
  padding: 20px;
  /* Adjust the padding as needed */
}

.about-us-header {
  background: #e9eaf0;
  padding: 20px;
  border-radius: 8px;
}

.about-us-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #007bff;
}

.subtitle {
  font-size: 1.25rem;
  color: #6c757d;
}

.about-us-content {
  margin-top: 20px;
}

.about-us-section {
  margin-bottom: 20px;
}

.about-us-section h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.about-us-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 20%;
  /* Adjust the width as needed */
  /* You can set a max-width to control the image size */
}