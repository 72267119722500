.footer {
    background-color: #1f0450;
    color: #ffffff;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container {
    display: flex;
    width: 100%;
    max-width: 90%;
    flex-wrap: wrap;
}

.footer-section {
    margin: 20px;
    flex: 1;
    min-width: 250px;
    animation: fadeInUp 0.8s ease-in-out;
}

.footer-section h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #f7a440;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.footer-section p,
.footer-section ul {
    margin-bottom: 20px;
    line-height: 1.6;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin: 10px 0;
}

.footer-section ul li a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #f7a440;
}

.footer-social {
    justify-content: center;
    gap: 15px;
}

.footer-social a {
    color: #ffffff;
    font-size: 1.5em;
    transition: transform 0.3s ease;
}

.footer-social a:hover {
    transform: scale(1.2);
    color: #f7a440;
}

.footer-copyright {
    border-top: 1px solid #333333;
    padding: 20px;
    text-align: center;
    width: 100%;
    font-size: 0.9em;
    background-color: #e9eaf0;
    color: black;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin: 20px 0;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}