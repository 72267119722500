/* Carousel Styling */
.carousel-item img {
    height: 500px;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
}

.carousel-item:hover img {
    transform: scale(1.1);
}

.carousel-caption h5 {
    font-size: 3em;
    color: #fff;
    animation: fadeInUp 1s ease;
    text-align: start;
}

.section-heading {
    text-align: center;
}

/* Tiles Section */
.tiles-section {
    padding: 50px;
    background-color: #f8f9fa;
}

.tiles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tile {
    width: 45%;
    background-color: #fff;
    padding: 30px;
    margin: 15px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
}

.tile-heading {
    color: #007bff;
    /* Blue shade for headings */
    margin-bottom: 10px;
    font-size: 1.8em;
}

.tile-description {
    color: #6c757d;
    /* Gray color for description */
    font-size: 1em;
    margin-bottom: 15px;
}

.animated-tile:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
}

/* Stats Section */
.stats-section {
    padding: 50px;
    background-color: #e9ecef;
}

.stats-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.stat {
    flex: 1;
    margin: 0 10px;
}

.stat-number {
    font-size: 3em;
    color: #007bff;
    /* Blue shade for numbers */
    margin-bottom: 10px;
    animation: countUp 2s ease;
}

.animated-stat:hover {
    transform: scale(1.2);
}

/* Clients Section */
.clients-section {
    padding: 50px;
    background-color: #f8f9fa;
}

.clients-container {
    display: flex; /* Align clients horizontally */
    align-items: center; /* Center content vertically */
    animation: scroll-clients 20s linear infinite; /* Continuous scrolling */
    white-space: nowrap; /* Prevent wrapping */
}


.client {
    flex: 0 0 auto; /* Prevent clients from shrinking */
    text-align: center;
    margin-right: 30px; /* Space between clients */
    min-width: 150px; /* Minimum width for each client */
}

.client img {
    height: 120px;
    margin-bottom: 10px;
    transition: transform 0.5s;
}

.clients-carousel {
    overflow: hidden; /* Hide content outside the viewport */
    position: relative;
}

.client p {
    color: #007bff;
    /* Blue shade for client names */
}

@keyframes scroll-clients {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%); /* Change this value to scroll less or more */
    }
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
    /* Clients Section */
.clients-section {
    padding: 50px;
    background-color: #f8f9fa;
}

.clients-container {
    display: flex; /* Align clients horizontally */
    align-items: center; /* Center content vertically */
    white-space: nowrap; /* Prevent wrapping */
    animation: none
}


.client {
    flex: 0 0 auto; /* Prevent clients from shrinking */
    text-align: center;
    margin-right: 30px; /* Space between clients */
    min-width: 150px; /* Minimum width for each client */
}

.client img {
    height: 120px;
    margin-bottom: 10px;
    transition: transform 0.5s;
}


.client p {
    color: #007bff;
    /* Blue shade for client names */
}


}

.animated-client:hover img {
    transform: rotate(360deg);
}

/* Why VBro Section */
.why-vbro-section {
    padding: 50px;
    background-color: #fff;
}

.why-vbro-container {
    position: relative;
    text-align: center;
    margin: 50px 0;
}

.why-vbro-center {
    font-size: 2.5em;
    color: black;
    /* Blue shade for center text */
    margin-bottom: 20px;
    animation: fadeIn 2s ease;
}

.why-vbro-points {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.why-vbro-point {
    width: 250px;
    margin: 25px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.5s, box-shadow 0.5s;
    text-align: center;
}

.why-vbro-point img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}

.why-vbro-point h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #007bff;
    /* Blue shade for point titles */
}

.animated-why-vbro-point:hover {
    transform: scale(1.1) rotate(-5deg);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes countUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Carousel Controls */
.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .tile {
        width: 90%;
    }

    .clients-container {
        display: block;
        white-space: normal;
        overflow: auto;
    }

    .client {
        display: block;
        margin: 10px auto;
    }
}

.know-more-button {
    display: inline-block;
    padding: 10px 20px;
    /* Adjust padding for button size */
    font-size: 16px;
    /* Adjust font size */
    color: white;
    /* Text color */
    background-color: #1f0450;
    /* Button background color */
    text-decoration: none;
    /* Remove underline from the link */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s;
    /* Smooth background color transition */
    margin-top: 10px;
    /* Space between button and tile content */
    display: block;
    /* Make the button take up full width */
    text-align: center;
    /* Center text inside button */
    max-width: 50%;
    /* make button center */
    margin: 0 auto;
}

.know-more-button:hover {
    background-color: darkblue;
    /* Darker blue on hover */
}