/* Header.css */
.company-icon {
  height: 40px;
  /* Adjust size as needed */
  width: auto;
  margin-right: 10px;
}

.navbar-nav {
  margin-left: auto;
  /* Align menu items to the right */
}

.navbar {
  background-color: #e9eaf0;
}

.company-logo {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
}

.logo-v {
  font-size: 34px;
  /* Larger size for "V" */
  font-weight: bold;
  color: #f39c12;
  /* Base color */
  background: linear-gradient(45deg, #f39c12, #d35400, #e74c3c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Apply gradient to text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
  margin-right: 5px;
  perspective: 1000px;
  /* Enable 3D perspective */
}

.company-logo:hover .logo-v {
  transform: rotateY(360deg);
  /* 3D flip effect */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.company-brand {
  display: flex;
  align-items: center;
}

.imageicon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
}